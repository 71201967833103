<template>
  <div class="container">
    <section class="section">
      <div class="container">
        <h4 class="title">Admin</h4>
        <div class="tabs">
          <ul>
            <li
              v-for="p in pages" 
              :key="p.name"
              :class="isActive(p.name)"
            >
              <a @click="go(p.path)">{{ p.label }}</a>
            </li>
          </ul>
        </div>
        <slot />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
       pages: [
          {
            label: "Dashboard",
            path: '/admin-dashboard',
            name: 'admin-dashboard'
          },
          {
            label: "Reports",
            path: '/admin-reports',
            name: 'admin-reports'
          },
          {
            label: "Payouts",
            path: '/admin-payouts',
            name: 'admin-payouts',
          },
          {
            label: "Transactions",
            path: '/admin-transactions',
            name: 'admin-transactions',
          },
          {
            label: 'Charities',
            path: '/admin-charities',
            name: 'admin-charities'
          },
          {
            label: "Businesses",
            path: '/admin-businesses',
            name: 'admin-businesses',
          },
          {
            label: "Users",
            path: '/admin-users',
            name: 'admin-users',
          },
          {
            label: "DMI Markets",
            path: '/admin-markets',
            name: 'admin-markets'
          }
       ]
    }
  },
  methods: {
    go(path) {
     this.$router.push(path);
    },
    isActive(name) {
      const found = name == this.$route.name;
      if (found) {
        return "is-active"
      } else {
        return null
      }
    }
  }
};

</script>

