<template>
  <div>
    <button
      :class="buttonClass"
      :disabled="disabled || submitting"
      @click="showConfirmationModal"
    >
      {{ buttonLabel }}
    </button>
    <BaseModal
      v-if="showModal"
      :title="title"
      @close="deny"
    >
      <slot></slot>
      <template v-slot:footer>
        <button
          class="button"
          @click="deny"
        >
          {{ denyLabel }}
        </button>
        <button
          class="button is-link"
          @click="confirm"
        >
          {{ confirmLabel }}
        </button>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/modal/BaseModal.vue"

export default {
  components: {
    BaseModal
  },
  computed: {
    buttonClass() {
      var klass = ["button"]
      if (this.buttonType) {
        klass.push(`is-${this.buttonType}`)
      }
      if (this.submitting) {
        klass.push("is-loading")
      }
      return klass.join(" ")
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    buttonLabel: {
      type: String,
      required: true
    },
    buttonType: {
      type: String,
      default: ""
    },
    confirmLabel: {
      type: String,
      default: "Yes"
    },
    denyLabel: {
      type: String,
      default: "No"
    },
    submitting: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    showConfirmationModal() {
      this.showModal = true
    },
    deny() {
      this.showModal = false
      this.$emit('deny')
    },
    confirm() {
      this.showModal = false
      this.$emit('confirm')
    }
  }
}
</script>
