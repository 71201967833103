<template>
  <AdminPage>

     <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <!--
          <div 
            class="field has-addons"
          >
            <p class="control">
              <span class="select">
                <select>
                  <option>All businesses</option>
                  <option>Top 10% lifetime revenue</option>
                  <option>Active - has balance</option>
                   <option>Active - no balance</option>
                  <option>Cancelled</option>
                </select>
              </span>
            </p>
            <p class="control">
              <span class="select">
                <select>
                  <option>All locations</option>
                  <option>Burlington, VT</option>
                  <option>..</option>
                </select>
              </span>
            </p>
          </div>
          -->
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <p class="level-item"><strong>{{ businessesCount }}</strong></p>
      </div>
    </nav>

    <b-table
      :data="businesses"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="updated_at"
      default-sort-direction="desc"
    >
      <b-table-column
        field="updated_at"
        label="Last Updated"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.updated_at).toLocaleDateString() }} {{ new Date(props.row.updated_at).toLocaleTimeString() }}
      </b-table-column>

      <b-table-column
        field="name"
        label="Business"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="is_onboarded"
        label="Status"
        sortable
        v-slot="props"
      >
        {{ status(props.row.is_onboarded) }}
      </b-table-column>

      <b-table-column
        field="owner"
        label="Owner"
        sortable
        v-slot="props"
      >
        {{ props.row.owner.name }}
      </b-table-column>

      <b-table-column
        field="locations"
        label="Locations"
        v-slot="props"
      >
        <ul class="content">
          <li
            v-for="l in props.row.locations"
            :key="l.id"
          >
            {{ l.formattedAddress }}
          </li>
        </ul>
      </b-table-column>

      <b-table-column
        field="stats.transactions_total"
        label="Transactions"
        numeric
        sortable
        v-slot="props"
      >
        {{ props.row.stats.transactions_total }}
      </b-table-column>

      <b-table-column
        field="stats.award_total"
        label="Total Award"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.stats.award_total).format() }}
      </b-table-column>

      <b-table-column
        field="balance"
        label="Balance"
        sortable
        v-slot="props"
      >
        {{ USD(props.row.balance).format() }}
      </b-table-column>

      <b-table-column
        label=""
        v-slot="props"
      >
        <ConfirmButton
          v-if="canMasqueradeAsBusiness(props.row)"
          buttonLabel="Masquerade As"
          @confirm="masqueradeAsBusiness(props.row)"
        >
          Are you sure you want to masquerade as <strong>{{ props.row.name }}</strong>? You will be able to edit data on behalf of the business, but should take extreme care in doing so.
        </ConfirmButton>
        <span
          v-if="isMasqueradingAsBusiness(props.row)"
          class="has-text-danger"
        >
          Masquerading As
        </span>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No businesses</div>
     </template>
    </b-table>
  </AdminPage>
</template>

<script>
import AdminPage from "@/components/AdminPage.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import api from "@/service/api.js";

export default {
  components: {
    AdminPage,
    ConfirmButton
  },
  computed: {
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    businessesCount: function() {
      return this.businesses.length
    }
  },
  data() {
    return {
      loading: false,
      businesses: []
    }
  },
  mounted() {
    this.loading = true

    api.getAdminBusinesses(this.currentUser.slug)
      .then(resp => {
        this.businesses = resp.data
      })
      .catch(error => {
        this.error = error.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    status(is_onboarded) {
      return (is_onboarded) ? "" : "onboarding"
    },
    isOwnBusiness(business) {
      this.currentUser.organization_access.some(b => b.slug === business.slug)
    },
    isMasqueradingAsBusiness(business) {
      return (this.isAdmin && !this.isOwnBusiness(business) && (this.currentBusiness.slug == business.slug)) ? true : false
    },
    canMasqueradeAsBusiness(business) {
      return (this.isAdmin && !this.isOwnBusiness(business) && business.is_onboarded && !this.isMasqueradingAsBusiness(business)) ? true : false
    },
    masqueradeAsBusiness(business) {
      if (this.canMasqueradeAsBusiness(business)) {
        const params = {
          user_slug: this.currentUser.slug,
          org_slug: business.slug
        }
        this.$store.dispatch("setCurrentBusiness", params)
      }
    }
  }

}
</script>
